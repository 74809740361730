nav .Search label {
	display: flex;
	align-items: center;
	text-align: center;
	height: 100%;
	width: 100%;
}
nav .Search input[type="text"] {
	border: none;
	appearance: none;
	padding-left: 35px;
	font-size: 0.8rem;
	width: 100%;
}
.Search svg#search-icon {
	position: absolute;
	height: 20px;
	width: 20px;
	top: 15px;
	left: 10px;
	fill: var(--text-muted-color);
}

.Search .results-container {
	position: absolute;
	top: 60px;
	left: 0px;
	width: 600px;
	background-color: #fff;
	box-shadow: var(--box-shadow);
	z-index: 600;
}

.Search .loading-container,
.Search .noresults-container {
	height: 50px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	font-size: 1rem;
}

.Search .noresults-container {
	margin-left: 40px;
}

.Search .loading-container .spinner svg {
	height: 30px;
	margin-right: 0.7rem;
}
.Search .loading-container .spinner svg circle {
	stroke-width: 8px;
}

.Search .row {
	display: flex;
	justify-content: space-between;
	margin: 0.5rem 0;
}

.Search .options-container {
	max-height: 90vh;
	overflow-y: auto;
}

.Search .options-container .option {
	cursor: pointer;
	padding: 1rem 2rem;
	font-size: 1rem;
	transition: background-color 150ms ease;
}
.Search .options-container .exact {
	font-weight: bold;
}
.Search .options-container .option:hover {
	background-color: var(--row-highlight);
}
.Search .options-container .option h1 {
	/* font-weight: lighter; */
	font: var(--font-label);
	color: var(--color-label);
}
.Search .options-container .option a {
	font-size: 0.8rem;
	font-weight: bolder;
	color: var(--link-text-color);
}
.Search .options-container .option a:after {
	content: "";
	display: block;
}
