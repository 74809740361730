div.error {
	margin: 3rem;
	color: var(--main-error-message-color);
	padding: 0 2rem;
	font-size: 1.5rem;
}

div.error > header {
	display: flex;
	align-items: center;
}

div.error > header h1 {
	padding: 0 1rem;
}

div.error > .message {
	padding: 1rem 70px;
}

div.error svg path {
	stroke: var(--main-error-message-color);
}

div.error svg path#dot {
	fill: var(--main-error-message-color);
}
div.error path#circle {
	fill: none;
	stroke-miterlimit: 10;
	stroke-width: 32px;
}

div.error path#bang {
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 32px;
}
