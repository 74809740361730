#filter-anchor .selected {
	padding: 0.3rem 0.5rem;
	font-size: 0.8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	transition: background-color 150ms ease;
	margin-bottom: 4px;
}
#filter-anchor .selected:hover {
	background-color: var(--carrot-lighter);
}

#filter-anchor .selected svg {
	fill: #000;
	fill-opacity: 0.4;
	width: 10px;
	cursor: pointer;
	transition: fill-opacity 150ms ease;
}

#filter-anchor .selected svg:hover {
	fill-opacity: 0.8;
}

#filter-anchor div.search-select label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 30px;
	background-color: #fff;
	font-size: 0.8rem;
	border-radius: 2px;
	border-style: solid;
	border-color: hsl(0, 0%, 80%);
	border-width: 1px;
	margin-bottom: 4px;
}

#filter-anchor div.search-select label input {
	font-size: 0.7rem;
	font-weight: 400;
}
