.AlertLine {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.AlertLine.success {
	background-color: var(--success-light);
	border-color: var(--success);
	color: var(--success-dark);
}

.AlertLine.warning {
	background-color: var(--warning-light);
	border-color: var(--warning);
	color: var(--warning-dark);
}

.AlertLine.danger {
	background-color: var(--danger-light);
	border-color: var(--danger);
	color: var(--danger-dark);
}

.AlertLine.info {
	background-color: var(--info);
	border-color: var(--info);
	color: var(--info-dark);
}

.AlertLine_icon {
	padding-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 2.25rem;
}

.AlertLine.success.AlertLine_icon svg {
	fill: var(--success-dark);
}
.AlertLine.warning.AlertLine_icon svg {
	fill: var(--warning-dark);
}
.AlertLine.danger.AlertLine_icon svg {
	fill: var(--danger-dark);
}
.AlertLine.info.AlertLine_icon svg {
	fill: var(--info-dark);
}

.AlertLine_message {
	width: calc(100% - 5.25rem);
	position: relative;
	padding: 0.75rem 1rem 0.75rem 1rem;
	border: 1px solid transparent;
}

button.AlertLine_close,
button.AlertLine_close:hover {
	width: 2rem;
	height: 2rem;
	padding: 0 1rem;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
	border: 0;
}

button.AlertLine_close svg {
	fill-opacity: 0.8;
	width: 0.75rem;
}

button.AlertLine_close:hover svg {
	fill-opacity: 1;
}
