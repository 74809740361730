.modalContainer {
	background-color: white;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	position: relative;
	padding: 2.5rem 2rem 1.7rem 2rem;
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	overflow-y: auto;
}

.modalContainer h1 {
	font-size: 1.2rem;
	font-weight: lighter;
	margin: 0 0 1rem 0;
}

.modalContainer h2 {
	font-size: 1rem;
	font-weight: lighter;
	margin: 0 0 1rem 0;
}

.modalContainer .title {
	margin: 0 0 0rem 0;
}

.modalContainer .title h2 {
	font-size: 1rem;
	font-weight: lighter;
	margin: 1rem 0;
}

.errorMessage {
	margin-top: 1rem;
	color: var(--danger);
	text-align: right;
	font-size: 0.9rem;
}

.buttonsContainer {
	display: flex;
	padding: 2.5rem 0 0 0;

	position: relative;
	flex-grow: 1;
	align-items: flex-end;
	justify-content: flex-end;
	right: 0;
	bottom: 0;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.buttons > button:not(:first-child) {
	margin-left: 1rem;
}

/* Close button */
.modalContainer .close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	fill: #000;
	fill-opacity: 0.4;
	cursor: pointer;
	transition: fill-opacity 0.3s ease;
}

.modalContainer .close:hover {
	fill-opacity: 0.8;
}

.modalContainer .hide {
	display: none;
}
