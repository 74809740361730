#filter-anchor label.filter-searchbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  background-color: #fff;
  font-size: 0.8rem;
  border-radius: 2px;
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  margin-bottom: 4px;
}
#filter-anchor label.filter-searchbox > input {
  border: none;
  padding-left: 8px;
}
