div.pagination {
	display: flex;
	height: 50px;
	justify-content: flex-end;
	padding: 1rem 0;
	user-select: none;
	font-size: 0.8rem;
}

div.pagination>a {
	padding: 0 5px;
	color: #777;
}

div.pagination>a.active {
	margin: 0 5px;
	color: #000;
}

div.pagination>span#elems {
	margin-right: 2rem;
	color: #777;
}

.flexColumns {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}