.link {
	font-size: 1rem;
	color: var(--link-text-color);
	font-weight: bolder;
	padding: 0;
	text-align: right;
}

.link a {
	font-size: 1rem;
	color: var(--link-text-color);
	font-weight: bolder;
	padding: 0;
	text-align: right;
}

.link.danger,
.link.danger a {
	color: var(--danger) !important;
}

.link.warning,
.link.warning a {
	color: var(--carrot) !important;
}
