.multiKeyValue {
}

.keyValue {
	width: 100%;
	margin-bottom: 0.75rem;
}

.keyValue form {
	width: 100%;
	display: flex;
	align-items: center;
}

.keyValue .inputField {
	width: 100%;
	margin-right: 1rem;
	flex-grow: 1;
}

.keyValue label {
	display: block;
	color: var(--color-label);
	font-weight: lighter;
	font: var(--font-label);

	padding-bottom: 0.75rem;
}

.keyValue form input {
	width: 100%;
	padding: 0.5rem;
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: var(--border-radius);
	font-size: 0.8rem;
}

.keyValue input:first-of-type {
	margin-right: 0.5rem;
}

.keyValue svg {
	cursor: pointer;
}

.keyValue svg.labelRow {
	margin-top: 2rem;
}

.keyValue svg line {
	fill: none;
	stroke: var(--icon-color);
	/*stroke: hsl(0, 0%, 50%);*/
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 48px;
}

.remove {
	width: 3rem;
}

.keyValue.addRow {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
	color: var(--link-text-color);
	font-weight: 600;
	margin-top: 1rem;
	cursor: pointer;
}

.keyValue.addRow svg {
	margin-right: 0.75rem;
}

.keyValue.addRow svg line {
	stroke: var(--link-text-color);
}
