.identities {
	display: flex;
	flex-direction: column;
	margin: 2rem;
	margin-bottom: 1rem;
}

.identities > h1 {
	font: var(--font-label);
	color: var(--color-label);
}

.identities > button {
	margin: 0.2rem 0;
	align-self: flex-end;
}

.tableIdentities th {
	padding-top: 0.5rem;
	justify-self: flex-end;
}

.tableIdentities td {
	padding-top: 0.75rem;
	justify-self: flex-end;
}

.tableIdentities th:first-of-type,
.tableIdentities td:first-of-type {
	justify-self: flex-start;
}

.tableIdentities {
	padding: 0.4rem 0;
	display: grid;
	border-collapse: collapse;
	/* grid-template-columns: 1fr 2rem 2rem 2rem; */

	position: relative;
	margin-top: 0.5rem;
	height: 100%;
	width: 100%;
	grid-template-columns: 100%;
	grid-template-rows: auto minmax(20px, 1fr);
	overflow: auto;
}

.tableIdentities > div:first-child {
	height: 1.5rem;
	margin-bottom: 5px;
	display: grid;
	border-bottom: var(--border);
	font-weight: bold;
	user-select: none;
	grid-template-columns: 1fr 1fr;
}

.tableIdentities > div:not(:first-child) {
	margin-bottom: 5px;
	display: grid;
	gap: 0.5rem;
}

.tableIdentities > div > div {
	display: grid;
	grid-template-columns: 2fr 1fr;
}

.tableIdentities > div > div > p {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.tableIdentities > div > div > p:last-child,
.tableIdentities > div:first-child > p:last-child {
	text-align: end;
}

.open-button,
.zoom-button,
.block-button,
.delete-button {
	cursor: pointer;
}

.customerRemoveButton {
	width: 0.95rem;
}

.zoom-button > svg > path {
	fill: var(--sub-button-color);
	transition: fill 150ms ease;
}

.zoom-button:hover > svg > path {
	fill: var(--sub-button-hover-color);
}

.open-button > svg > polyline {
	fill: none;
	stroke: rgba(10, 112, 69, 0.5);
	stroke-linecap: square;
	stroke-miterlimit: 10;
	stroke-width: 70px;
	transition: stroke 150ms ease;
}

.active > svg > polyline,
.open-button:hover > svg > polyline {
	stroke: rgba(10, 112, 69, 1);
}

.end-align {
	justify-self: end;
}

.block-button > svg > path,
.delete-button > svg > path {
	fill: rgba(125, 32, 3, 0.3);
	transition: fill 150ms ease;
}

.active > svg > path,
.block-button:hover > svg > path,
.delete-button:hover > svg > path {
	fill: rgba(125, 32, 3, 0.8);
}

.buttonSpaceBefore {
	margin-top: 1rem;
}

.visible {
	display: block;
}
.visible {
	display: none;
}
