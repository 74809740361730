.globalAlert {
	position: absolute;
	z-index: 100001;
	left: 1rem;
	bottom: -9rem;
	margin-right: 1rem !important;
	margin-bottom: 0;
	border-radius: var(--border-radius);
	/*width: 25rem;*/
	width: initial;
	max-width: calc(100% - 2rem);
	font-weight: 600 !important;
	transition: bottom 1s;
}

.globalAlert.show {
	bottom: 1rem;
	transition: bottom 1s;
}

.globalAlert > button:last-of-type {
	width: 2rem !important;
	height: initial !important;
	padding: initial !important;
	justify-content: initial !important;
	flex-grow: unset !important;
	margin-left: 1rem;
}
