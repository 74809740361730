.svgButton {
	cursor: pointer;
}

.disable,
.delete {
	fill: #be9081; /* rgba(125, 32, 3, 0.5) */
}
.disable:hover,
.delete:hover {
	fill: #974d35; /* rgba(125, 32, 3, 0.8) */
}

.disable {
	width: 1.2rem;
}

.delete {
	width: 1rem;
}

.enable {
	fill: none;
	width: 1.4rem;
}
.enable > polyline {
	fill: none;
	stroke: #85b8a2; /* rgba(10, 112, 69, 0.5) */
	stroke-linecap: square;
	stroke-miterlimit: 10;
	stroke-width: 70px;
	transition: stroke 150ms ease;
}
.enable:hover > polyline {
	stroke: #3b8d6a; /* var(--button-hover-color) */
}

.remove {
	fill: #808080; /* rgba(0, 0, 0, 0.5) */
	width: 1.1rem;
}
.remove:hover {
	fill: #333333; /* rgba(0, 0, 0, 0.8) */
}
