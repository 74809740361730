#filter-anchor label.filter-searchbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 30px;
	background-color: #fff;
	font-size: 0.7rem;
	border-radius: 2px;
	border-style: solid;
	border-color: hsl(0, 0%, 80%);
	border-width: 1px;
	margin-bottom: 4px;
}
#filter-anchor label.filter-searchbox > input {
	border: none;
	padding-left: 8px;
	flex-grow: 1;
	font-size: 0.7rem;
}
#filter-anchor .filter-searchbox.searchOnceInput input {
	font-size: 0.7rem;
	width: 8.35rem;
	padding-right: 0.5rem;
}
#filter-anchor .filter-searchbox.searchOnceInput > button {
	width: 1.5rem;
	padding: 0.4rem 0 !important;
	border-radius: 0 1px 1px 0;
}

#filter-anchor .filter-searchbox.searchOnceInput > button svg {
	width: 0.65rem;
	height: 0.65rem;
}
#filter-anchor .filter-searchbox.searchOnceInput > button.clear {
	background-color: transparent;
}
#filter-anchor .filter-searchbox.searchOnceInput > button.clear svg {
	fill: #000;
	opacity: 0.4;
}
#filter-anchor .filter-searchbox.searchOnceInput > button.clear:hover svg {
	opacity: 1;
}
