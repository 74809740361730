.gridBox {
	width: 100%;
	display: grid;
}

.dashboard-tile-group {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 60px;
	gap: 1rem;
}

.dashboard-tile {
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: var(--border);
	padding: 1rem;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto minmax(20px, 1fr);
}
