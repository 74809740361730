.point-history {
	display: flex;
	flex-direction: column;
	margin: 2rem;
	padding-bottom: 0;
}

.point-history-entry {
	border-bottom: var(--border);
	padding: 10px 0;
}

.point-history-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 24px;
}

.point-history-data {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	column-gap: 10px;
	border-collapse: collapse;
	margin: 10px 0 5px 24px;
}

.point-history-data-header {
	text-align: left;
}

.point-history-data-header th {
	font-weight: normal;
	color: #aaa;
}

.undefined-property {
	color: #aaa;
}

.nested-object-property {
	padding-left: 10px;
}

.data-row-expand {
	overflow-x: auto !important;
	text-overflow: unset !important;
}

.point-history-data-row ::-webkit-scrollbar {
	height: 4px;
}

.point-history-data-row ::-webkit-scrollbar-thumb {
	background: #eee;
	border-radius: 4px;
}

.point-history-data-row td {
	margin-top: 5px;
	align-self: center;
	overflow-x: hidden;
}

.flex-row {
	display: flex;
	flex-flow: row;
}

.history-title {
	margin: 20px 0 10px 0;
}

.history-title h1 {
	font-size: 1.1rem;
	font-weight: bold;
}

.history-title h2 {
	font-size: 1.1rem;
	font-weight: lighter;
}

.history-title .spacer {
	margin: 0 10px;
	width: 2px;
	background-color: #aaa;
}

.history-tabs {
	align-items: center;
	margin: 20px 0;
	font-size: 1.1rem;
	color: #aaa;
}

.history-tabs div {
	margin-right: 0.7rem;
	display: flex;
	align-items: center;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
}

.history-tabs .active {
	border-bottom: 2px solid var(--primary-color);
	color: black;
}

.history-modal {
	position: relative;
	width: 75%;
	min-width: 500px;
	max-width: 1200px;
	height: 70%;
	min-height: 310px;
	display: flex;
	flex-direction: row;
	background-color: #fff;
	box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
	transition: 0.5s ease-in-out;
}

.history-map {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 0%;
	overflow: hidden;
	transition: 0.5s ease-in-out;
}

.show-map {
	width: 80%;
}

.show-map .history-map {
	width: 100%;
}

.history-map-el {
	height: 100%;
	min-width: 35vw;
	/* map does not work when width 0 */
}

.history-map-el .highlight {
	stroke: red;
	stroke-opacity: 0.6;
	stroke-width: 2px;
}

.history-map-el .marker {
	fill-opacity: 1;
	fill: var(--primary-color);
	stroke: none;
	stroke-width: 2px;
}

.history-map-el .last {
	stroke: red;
}

.history-map-el .popup p {
	margin: 2px 0;
}

.history-data {
	display: flex;
	flex-direction: column;
	min-width: 500px;
	width: 100%;
	height: 100%;
	background-color: inherit;
	overflow-y: auto;
	transition: 0.5s ease-in-out;
}

/* Close button */
.history-modal .close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	fill: #000;
	fill-opacity: 0.4;
	cursor: pointer;
	transition: fill-opacity 0.3s ease;
}

.history-modal .close:hover {
	fill-opacity: 0.8;
}

.drop-down {
	transition: 0.2s ease;
}

.pointer {
	cursor: pointer;
}
