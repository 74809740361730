.enable {
	fill: none;
	width: 1.4rem;
}
.enable > polyline {
	fill: none;
	stroke: var(--dark-grey);
	stroke-linecap: square;
	stroke-miterlimit: 10;
	stroke-width: 70px;
	transition: stroke 150ms ease;
}
