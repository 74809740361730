/* .dashboard {
} */

.dashboard-nav {
	display: flex;
	margin-left: 1rem;
	margin-top: 1rem;
}

.dashboard-grid {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-template-rows: repeat(auto-fill, 60px);
	grid-auto-rows: 60px;
	padding: 1rem;
	gap: 1rem;
}

.dashboard-tile-group {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 60px;
	gap: 1rem;
}

.dashboard-tile {
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: var(--border);
	padding: 1rem;

	position: relative;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto minmax(20px, 1fr);
}

/* For Nivo charts text */
.dashboard-tile text {
	font-family: var(--font-family-secondary) !important;
}

.tile-title {
	font-size: 20px;
	font-weight: bold;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.number-tile {
	justify-content: start;
	height: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.number-tile h1 {
	font-size: 36px;
}

.number-tile .flex {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	text-align: center;
	justify-content: center;
	color: var(--carrot);
}

.menu-tile-box {
	height: 100%;
	width: 100%;
	background-color: var(--grey);
	border-radius: 1rem;
	cursor: pointer;
}

.menu-tile-icon-circle {
	background-color: var(--lime);
	border-radius: 50%;
	width: 75px;
	height: 75px;
	margin-left: 1rem;
}

.tile-content-flex {
	display: flex;
	flex-flow: column;
}

.table-tile {
	display: grid;
	position: relative;
	margin-top: 0.5rem;
	height: calc(100% - 0.5rem);
	width: 100%;
	grid-template-columns: 100%;
	grid-template-rows: auto minmax(20px, 1fr);
	overflow: auto;
}

.table-tile p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.table-tile .header {
	height: 1.5rem;
	margin-bottom: 5px;
	top: 0;
	display: grid;
	position: sticky;
	background-color: var(--grey);
	border-bottom: var(--border);
	font-weight: bold;
	cursor: pointer;
	user-select: none;
}

.table-tile .body {
	display: grid;
	/* grid-template-rows: repeat(auto-fill, 1fr); */
	grid-auto-rows: 25px;
}

.table-tile .row {
	display: grid;
	height: 25px;
}

.table-tile .row:nth-child(2n) {
	background-color: var(--carrot-light);
}

.table-tile .total {
	display: grid;
	height: 25px;
	position: sticky;
	bottom: 0;
	background-color: var(--grey-lighter);
}

/* filter rules */
.filter {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 99;
}

.filter .filter-modal {
	position: absolute;
	background-color: var(--header-bg-color);
	border: var(--border);
	padding: 1rem;
	right: 0;
}

:root {
	--lime-1: var(--lime); /* hsl(62, 78%, 64%); */
	--lime-2: hsl(62, 78%, 55%);
	--lime-3: hsl(62, 78%, 45%);
	--lime-4: hsl(62, 78%, 70%);
	--lime-5: hsl(62, 78%, 75%);

	--fucales-1: var(--fucales); /* hsl(60, 32%, 34%) */
	--fucales-2: hsl(60, 32%, 40%);
	--fucales-3: hsl(60, 32%, 45%);
	--fucales-4: hsl(60, 32%, 50%);
	--fucales-5: hsl(60, 32%, 55%);
	--fucales-6: hsl(60, 32%, 60%);
	--fucales-7: hsl(60, 32%, 65%);

	--carrot-1: hsl(24, 100%, 40%);
	--carrot-2: hsl(24, 100%, 45%);
	--carrot-3: hsl(24, 100%, 50%);
	--carrot-4: var(--carrot); /* hsl(24, 100%, 61%); */
	--carrot-5: hsl(24, 100%, 66%);
	--carrot-6: hsl(24, 100%, 70%);
	--carrot-7: hsl(24, 100%, 75%);

	--beige-1: var(--beige); /* hsl(26, 42%, 90%); */
	--beige-2: hsl(26, 42%, 80%);
	--beige-3: hsl(26, 42%, 75%);
	--beige-4: hsl(26, 42%, 70%);
	--beige-5: hsl(26, 42%, 65%);
	--beige-6: hsl(26, 42%, 60%);
	--beige-7: hsl(26, 42%, 55%);
	--beige-8: hsl(26, 42%, 50%);

	--black-1: hsl(0, 0%, 35%);
	--black-2: hsl(0, 0%, 40%);
	--black-3: hsl(0, 0%, 45%);
	--black-4: hsl(0, 0%, 50%);
	--black-5: hsl(0, 0%, 55%);
	--black-6: hsl(0, 0%, 60%);

	--lightgrey-1: var(--grey); /* hsl(0, 24%, 96%) */
	--lightgrey-2: hsl(0, 24%, 90%);
	--lightgrey-3: hsl(0, 24%, 85%);
	--lightgrey-4: hsl(0, 24%, 80%);
	--lightgrey-5: hsl(0, 24%, 75%);
	--lightgrey-6: hsl(0, 24%, 70%);
}
