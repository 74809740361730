.config-modal {
	margin: 0rem -1rem;
	display: flex;
	flex-direction: row;
}

.config-modal > div {
	flex: 1;
	margin: 0 1rem;
	margin-top: 0.5rem;
}

.config-modal-header {
	font-size: 1.2rem;
	font-weight: normal;
	margin-top: 2rem;
	margin-bottom: 1rem;
	/* margin-bottom: 1.2rem; */
}

.grey-label {
	font-size: 0.8rem;
	font-weight: lighter;
	color: #aaa;
	margin: 0.2rem 0;
}

.green-button {
	font-size: 0.9rem;
	font-weight: bold;
	white-space: pre;
	margin: 0 0 0 2rem;
	line-height: 35px;
	text-align: right;
	color: var(--button-text-color);
	cursor: pointer;
	background-color: var(--button-color);
	padding: 0 1rem;
	border-radius: 4px;
}

.modal-spacer {
	margin-bottom: 1rem;
}

.modal-space-top {
	margin-top: 0.8rem;
}

.input-form {
	margin-bottom: 0.8rem;
	width: 100%;
	position: relative;
}

.input-form > .flex-row {
	align-items: center;
}

.input-form > .flex-row > p {
	flex: 1;
}

.input-form .input-select {
	width: 100%;
	/* margin-bottom: 1rem; */
}

.input-form input {
	height: 35px;
	padding: 0 0.5rem;
	font-size: 0.8rem;
	margin-right: 0.3rem;
	width: 100%;
	border: var(--border2);
}

.paste-import-input {
	height: 35px;
	width: 300px;
	padding: 0 0.5rem;
	font-size: 0.8rem;
	color: #888;
}

.helper-dialog {
	padding: 0.5rem;
	position: absolute;
	z-index: 1;
	box-shadow: var(--box-shadow);
	background-color: var(--main-bg-color);
	border: var(--border2);
	font-size: 0.8rem;
	line-height: 1.2rem;
	white-space: pre-wrap;
}

input.error-border {
	border: 1px solid #ff000080;
}

input.warning-border {
	border: 1px solid #ff9900;
}

.input-form svg line {
	fill: none;
	stroke: hsl(0, 0%, 50%);
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 32px;
}

.input-form svg {
	cursor: pointer;
}

.add-new-point-row {
	display: flex;
	align-items: center;
}

.point-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0.5rem 0;
	margin-left: 0.4rem;
}

.point-row svg {
	cursor: pointer;
}

.add-new-point-row svg {
	fill: none;
	stroke: #0a7045;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 32px;
	cursor: pointer;
	margin-bottom: 0.8rem;
}

.error-message {
	color: red;
}

.warning-message {
	color: #ff9900;
}

.tabs {
	font-size: 1.2rem;
	font-weight: lighter;
	margin: 2.5rem 0 2rem 0;
	gap: 1.5rem;
}

.tabs p {
	cursor: pointer;
}

.tabs .active {
	border-bottom: 2px solid var(--primary-color);
}
