.addIdentityContainer {
	min-height: 400px;
	/* margin-bottom: 6rem; */
	margin-top: 2rem;
}

.addIdentityContainer :global .ReactVirtualized__Grid__innerScrollContainer {
	/* margin-bottom: 100px; */
	min-height: 350px;
}

.addIdentityContainer :global .ReactVirtualized__Table__row > div:first-child,
.addIdentityContainer :global .ReactVirtualized__Table > div:first-child > div:first-child {
	margin-left: 1rem;
}

.addIdentitySection {
	display: flex;
	align-items: center;
	margin-bottom: 3rem;
}

.addIdentitySection > div {
	width: 200px;
	max-width: 100%;
	margin-right: 1rem;
}

.actions {
	display: flex;
	align-items: center;
	height: 2.25rem;
	margin-left: 0.5rem;
}

.actions span {
	width: 1.5rem;
}

.actions span:first-of-type {
	margin-right: 0.5rem;
}

.enabledText {
	color: var(--black);
	font-weight: 600;
}

.disabledText {
	color: #974d35;
	font-weight: 600;
}

.addIdentityContainer input:disabled {
	border-color: #e0e0e0;
}

.bigTitle {
	font-size: 24px;
	font-weight: bold;
	margin: 0 0 1rem 0;
}

.dotMenu {
	height: 28px;
	cursor: pointer;
}

.hint {
	height: 12px;
	margin-bottom: 7px;
}

.actionButtons {
	display: flex;
	gap: 12px;
	margin-top: 0.5rem;
}

.tableRow {
	border-bottom: var(--border);
	display: grid;
	overflow: visible !important;
}
