nav {
	display: grid;
	grid-template-columns: 200px 1fr;
	border-bottom: var(--border);
	height: 50px;
	box-sizing: border-box;
	user-select: none;
}

nav section {
	display: grid;
	grid-template-columns: 1fr 230px;
}

nav section > * {
	display: flex;
	border-left: var(--border);
	align-items: center;
	padding: 0 1rem;
	font-size: 0.8rem;
}

nav .Search {
	position: relative;
}

.User {
	position: relative;
	height: 100%;
	justify-content: right;
	column-gap: 1rem;
	cursor: pointer;
	padding: 0 1rem;
}

.User ul {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	display: block;
	overflow: hidden;
	transition: all 0.5s ease;

	width: 230px;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 50px;
	box-shadow: var(--box-shadow);
	z-index: 501;
}

.User.active ul {
	opacity: 1;
	max-height: 200px;
	visibility: visible;
	background-color: #fff;
}

.User li {
	display: flex;
	padding: 0 2rem;
	height: 50px;
	align-items: center;
	border-bottom: var(--border);
	transition: background-color 0.3s ease;
}
.User li:hover {
	background-color: var(--carrot-lighter);
}

.User li#language-select {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.User .locale {
	display: flex;
	align-items: center;
}

.User .locale svg {
	margin: 0.5rem 0;
	height: 20px;
	width: 30px;
}

.User .locale span {
	margin-left: 0.5rem;
}

.User .locale.active span {
	font-weight: bold;
}

.User .username2 {
	display: none;
}

@media only screen and (max-width: 650px) {
	nav {
		grid-template-columns: 140px 1fr;
	}
	nav section {
		display: grid;
		grid-template-columns: 1fr 180px;
	}
}

@media only screen and (max-width: 550px) {
	nav section {
		grid-template-columns: 1fr 4rem;
	}
	.User .username1 {
		display: none;
	}
	.User .username2 {
		display: flex;
	}
}
