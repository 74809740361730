div#modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 10000;
	background-color: rgba(0, 0, 0, 30%);
	backdrop-filter: blur(1px);

	display: flex;
	justify-content: center;
	align-items: center;
}
