.themed-button {
	padding: 0.5rem 1rem;
	color: var(--button-text-color);
	border-radius: 4px;
	cursor: pointer;
	background-color: var(--main-bg-color);
	transition: background-color 150ms ease;
	/* border: 1px solid #cee2da; */
	display: flex;
	align-items: center;
}

.themed-button > svg {
	margin-right: 0.5rem;
}

.primary {
	color: var(--button-text-color);
	background-color: var(--button-color);
}
.primary:hover {
	background-color: var(--button-hover-color);
}
.primary:active {
	background-color: var(--button-hover-color);
}
.svg-primary * {
	fill: var(--black);
	stroke: var(--black);
}

.secondary {
	background-color: transparent;
}
.secondary:hover {
	background-color: var(--button-hover-color);
	border-color: var(--button-hover-color);
}
.secondary:active {
	background-color: transparent;
	/* border-color: rgba(10, 112, 69, 0.35); */
}
.svg-secondary * {
	fill: var(--primary-color);
	stroke: var(--primary-color);
}

.third {
	opacity: 0.85;
	background-color: transparent;
	border-color: transparent;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.third:hover {
	opacity: 1;
	background-color: transparent;
}
.third:active {
	background-color: transparent;
}
.svg-third * {
	fill: var(--primary-color);
	stroke: var(--primary-color);
}

.background {
	color: rgba(20, 23, 25, 0.7);
	/* border-color: #f7fafc; */
	/* background-color: #f7fafc; */
}
.background:hover {
	border-color: var(--button-color);
	background-color: var(--button-color);
}
.background:active {
	border-color: var(--button-hover-color);
	background-color: var(--button-hover-color);
}
.svg-background * {
	fill: rgba(20, 23, 25, 0.7);
	stroke: rgba(20, 23, 25, 0.7);
}

.danger {
	color: var(--danger);
	border-color: var(--danger-light);
	background-color: var(--danger-light);
}
.danger:hover {
	background-color: rgba(256, 42, 42, 0.25);
}
.danger:active {
	background-color: rgba(256, 42, 42, 0.35);
}
.svg-danger * {
	fill: var(--danger);
	stroke: var(--danger);
}

.warning {
	color: var(--warning);
	border-color: var(--warning-light);
	background-color: var(--warning-light);
}
.warning:hover {
	opacity: 0.75;
}
.warning:active {
	opacity: 0.65;
}
.svg-warning * {
	fill: var(--warning);
	stroke: var(--warning);
}
