/* tr */
.headerTr {
	display: grid;
	align-content: center;
	width: 100%;
	user-select: auto;
	font-size: 1rem;
	font-weight: lighter;
	text-overflow: ellipsis;
	background-color: #fff;
	overflow: hidden;
	white-space: nowrap;
	font-weight: bold;
}

/* th */
.headerTh {
	cursor: pointer;
	padding: 0 0 1rem 0;
	display: flex;
	align-content: center;
	position: sticky;
	top: 0;
	color: var(--color-label);
	user-select: none;
}

.dataGridEditable thead,
.dataGridEditable tbody {
	display: contents;
}

/*tr */
.bodyTr {
	display: grid;
	align-content: center;
	width: 100%;
}

/*td*/
.bodyTr > .bodyTd {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*td .cell*/
/* .innerCell {
	padding-left: 1rem;
} */

.innerCell {
	padding-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: stretch;
}

.bodyTr > div:last-of-type .innerCell {
	padding-right: 0;
}

.innerCell > input {
	padding: 0 0.5rem;
	height: 32px;
	width: 100%;
	font-size: 16px;
}

/* .headerTr:last-child .innerCell,
.bodyTr:last-child .innerCell {
	padding-right: 1rem;
} */
