aside {
	white-space: nowrap;
	user-select: none;
	display: flex;
	flex-direction: column;
	/* overflow-y: auto; // causes date picker to be partially hidden in side menu */
}

aside ul {
	display: flex;
	flex-direction: column;
}

aside li,
ul {
	transition: all 150ms ease;
}

aside li a.active,
aside li a:hover {
	background-color: var(--carrot-lighter);
}

aside a {
	padding-left: 2rem;
	display: flex;
	align-items: center;
	height: 50px;
	transition: padding-left 150ms ease;
}
aside a.minimized {
	padding-left: 1rem;
}

aside a > * {
	margin-right: 1rem;
}
aside li path {
	fill-opacity: 0.8;
}

aside > div {
	margin-top: auto;
	cursor: pointer;
	display: flex;
	height: 50px;
	justify-content: flex-end;
	align-items: center;
	padding-right: 13px;
}

svg#chevron {
	stroke: black;
	stroke-opacity: 0.6;
	stroke-width: 2;
	fill: none;
}

.menu-list {
	margin: 0.5rem 0;
	padding-left: 1rem;
}

.menu-list.minimized {
	padding-left: 0;
}

.menu-list li {
	height: 35px;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
}
.menu-list li a {
	padding-left: 1rem;
	display: block;
	position: relative;
	top: 8px;
	height: 35px;
	color: rgba(0, 0, 0, 0.7);
	transition: color 150ms ease-in;
}

.menu-list li a.minimized {
	width: 35px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.menu-list li:hover a,
.menu-list li a.active {
	color: rgba(0, 0, 0);
	background-color: transparent;
}

.menu-list li a:after {
	display: block;
	content: "";
	width: 0;
	height: 2px;
	background-color: var(--primary-color);
	transition: width 150ms ease-in;
}

.menu-list li a.active:after,
.menu-list li:hover a:after {
	width: 100%;
}
