.points {
	margin: 2rem;
	margin-bottom: 4rem;
}

.points > h1 {
	font: var(--font-label);
	color: var(--color-label);
	margin-bottom: 1rem;
}

.points > button {
	margin: 0.5rem 0;
	align-self: flex-end;
}

.open-button,
.zoom-button,
.block-button,
.delete-button {
	cursor: pointer;
}

.zoom-button > svg > path {
	fill: var(--sub-button-color);
	transition: fill 150ms ease;
}

.zoom-button:hover > svg > path {
	fill: var(--sub-button-hover-color);
}

.pointRemoveButton {
	width: 0.95rem;
}

.buttonSpaceBefore {
	margin-top: 2rem;
}

.pointsFlexBox > * {
	margin-bottom: 1rem;
}

.pointsFlexBox > *:first-of-type {
	margin-right: auto;
}
