.fillLevelCircleWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 25px;
	overflow-y: hidden;
	overflow-x: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.fillLevelCircleWrapper::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.fillLevelCircleWrapper span {
	width: 14px;
	margin-right: 0.5rem;
}
