#List {
	display: grid;
	grid-template-columns: 1fr 0px;
	height: 100%;
	transition: grid-template-columns 300ms ease-out;
}

@media (min-width: 1400px) {
	#List.drawer-open {
		grid-template-columns: 1fr 500px;
	}
}

.listmenu {
	display: flex;
	margin-left: 3rem;
	margin-top: 1rem;
	justify-content: space-between;
	align-items: center;
}

.listmenu ul {
	display: flex;
	align-items: center;
}

.listmenu li {
	margin-right: 0.7rem;
	height: 50px;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
}

.listmenu li a {
	color: rgba(0, 0, 0, 0.7);
	transition: color 150ms ease-in;
}

.listmenu li:hover a,
.listmenu li a.active {
	color: var(--black);
}

.listmenu li a:after {
	display: block;
	content: "";
	width: 0;
	height: 2px;
	background-color: var(--carrot);
	transition: width 150ms ease-in;
}

.listmenu li a.active:after,
.listmenu li:hover a:after {
	width: 100%;
}

.section {
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
}

.section-title {
	font-weight: bold;
}
.section-title ~ .checkbox {
	margin-top: 0.2rem;
	margin-left: 0.8rem;
}
