label.text-input-field {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 2px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: hsl(0, 0%, 100%);
  ;
  height: 35px;
}


label.text-input-field input {
  font-size: 0.8rem;
  font-weight: 300;
  appearance: none;
  border: none;
  padding: 0 0.5rem;
  width: 100%;
  text-overflow: ellipsis;
}

label.text-input-error-border {
  border: 1px solid #ff000080;
}