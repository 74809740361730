.extKeysAndPropsModal {
}

.flex-row {
	display: flex;
	flex-flow: row;
}

.tabMenu {
	display: flex;
	align-items: center;
	margin: 0 0 2rem 0;
	font-size: 1rem;
	color: #aaa;
}

.tab {
	margin-right: 1rem;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
}

.tab.active {
	border-bottom: 2px solid var(--primary-color);
	color: black;
}

.tabContent {
	display: none;
}

.tabContent.active {
	display: inherit;
}

.buttonsContainer {
	display: flex;
	padding: 2.5rem 0 1.75rem 0;

	position: relative;
	flex-grow: 1;
	align-items: flex-end;
	justify-content: flex-end;
	right: 0;
	bottom: 0;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.buttons > button:not(:first-child) {
	margin-left: 0.75rem;
}
