div.toolbar {
  margin-left: auto;
  margin-right: 2rem;
}

.toolbar button svg {
  fill: none;
  stroke: hsl(0, 0%, 50%);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
  transition: stroke 200ms ease;
}
