.edit-accesspoints-container {
  display: flex;
  justify-content: space-between;
}

.edit-accesspoints-container .select-accesspoints-container {
  flex: 1;
}
.edit-accesspoints-container .select-accesspoints-container:nth-of-type(1) {
  margin-right: 2rem;
}
.edit-accesspoints-container .select-accesspoints-container:nth-of-type(2) {
  margin-left: 2rem;
}
