#isStagingEnv {
	width: 100%;
	height: 50px;
	padding: 0.85rem;
	background-color: var(--carrot-darker);
	display: flex;
	position: absolute;
	align-content: space-around;
	justify-content: center;
	bottom: 0;
	z-index: 401;
}

#isStagingEnv svg {
	height: 1.25rem;
	margin-right: 0.5rem;
}
