#filter-anchor {
	margin: 0 1rem;
	position: sticky;
	top: 0;
	user-select: none;
	transition: opacity 400ms ease;
	z-index: 400;
}

#filter-anchor > span:nth-of-type(1) {
	color: #999;
	font-weight: bold;
	display: inline-block;
	margin: 0.7rem 0;
}
