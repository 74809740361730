.new-allocation {
  display: flex;
  justify-content: space-between;
}

.new-allocation .allocation-form > form > *:not(:last-child) {
  margin: 4px 0;
}
.new-allocation .allocation-form > form > *:first-child {
  margin: 1rem 0;
}
