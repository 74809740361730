#main .ReactVirtualized__Table {
	display: grid;
	grid-template-columns: minmax(50px, 50px) 1fr 1fr 1fr;
	box-shadow: var(--box-shadow);
	min-width: calc(100% - 5em);
	user-select: auto;
}

#main .ReactVirtualized__Table * {
	user-select: auto;
}

thead,
tbody,
tr,
.tr {
	display: contents;
}

#main thead,
#main tbody,
#main tr,
#main .tr,
#main .ReactVirtualized__Table__headerRow {
	display: grid;
	grid-template-columns: minmax(50px, 50px) 1fr 1fr 1fr;
	box-shadow: var(--box-shadow);
	min-width: calc(100% - 5em);
	user-select: auto;
}

#main .ReactVirtualized__Table__headerRow:first-of-type {
	box-shadow: none;
}

#main th,
#main .th,
#main .ReactVirtualized__Table__headerColumn {
	position: sticky;
	top: 0;
	color: #888;
	height: 50px;
}

#main th,
#main .th,
#main .ReactVirtualized__Table__headerColumn {
	cursor: pointer;
}

#main th,
#main td,
#main .th,
#main .td,
#main .ReactVirtualized__Table__headerColumn,
#main .ReactVirtualized__Table__rowColumn {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	font-size: 0.9rem;
	font-weight: lighter;
	text-overflow: ellipsis;
	background-color: #fff;
	overflow: hidden;
	white-space: nowrap;
	border-bottom: var(--border);
}

#main .ReactVirtualized__Table__headerColumn {
	font: var(--font-table-header);
	color: var(--color-label);
}

#main th:first-of-type,
#main td:first-of-type,
#main .th:first-of-type,
#main .td:first-of-type,
#main .ReactVirtualized__Table__headerColumn:first-of-type,
#main .ReactVirtualized__Table__rowColumn:first-of-type {
	background-color: transparent;
	border-bottom-color: transparent;
	font-size: 1rem;
}

#main td,
#main .td,
#main .ReactVirtualized__Table__rowColumn {
	height: 45px;
	transition: background-color 150ms ease;
}

#main .td span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#main .ReactVirtualized__Table__row {
	height: 45px;
}

#main tr.active td,
#main tr:hover td,
#main .tr.active .td,
#main .tr:hover .td,
#main .ReactVirtualized__Table__row:active .ReactVirtualized__Table__rowColumn,
#main .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn {
	background-color: var(--row-highlight);
}

#main tr.active td:first-of-type,
#main tr:hover td:first-of-type,
#main .tr.active .td:first-of-type,
#main .tr:hover .td:first-of-type,
#main .ReactVirtualized__Table__row:active .ReactVirtualized__Table__rowColumn:first-of-type,
#main .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:first-of-type {
	background-color: transparent;
}

#datagrid-container {
	display: flex;
	height: 100%;
	width: 100%;
}

#datagrid-checkboxes {
	width: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	margin-right: -2.6rem;
	margin-left: 0.6rem;
	position: relative;
	/* z-index: 2; */
}

#datagrid-checkboxes > label {
	width: 2rem;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
}

#datagrid-checkboxes>label:hover>div.checkbox>label,
#datagrid-checkboxes>label>div.checkbox>input:checked~label

/* .td.ReactVirtualized__Table__rowColumn div.checkbox label  */ {
	opacity: 1;
}

#datagrid-checkboxes > label > div.checkbox {
	margin: 0;
}

#datagrid-checkboxes > label > div.checkbox > label {
	text-indent: 0;
}

#datagrid-checkboxes > label#header {
	height: 50px;
}

#datagrid-container > .actions {
	position: fixed;
	min-height: 5rem;
	width: calc(100% - 450px - 3rem);
	left: calc(450px + 1rem);
	bottom: 2rem;
	background-color: #fff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

	transform: translate(0, 10rem);
	transition: transform 400ms ease;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	user-select: none;
}

#datagrid-container > .actions > span {
	font-size: 0.8rem;
	font-weight: bold;
	margin: 0 0.8rem 0 1.5rem;
}

#datagrid-container > .actions > button {
	margin: 0 0.4rem;
}

#datagrid-container > .actions > svg {
	fill: #000;
	fill-opacity: 0.4;
	cursor: pointer;
	transition: fill-opacity 0.3s ease;
	margin-left: auto;
	margin-right: 1.5rem;
}

#datagrid-container > .actions > svg:hover {
	fill-opacity: 0.8;
}

#main td.active,
#main .td.active,
#main .ReactVirtualized__Table__rowColumn.active {
	padding: 0 0.6rem;
}

#main td.active span,
#main .td.active span,
#main .ReactVirtualized__Table__rowColumn.active span {
	padding: 8px 0.4rem;
	border: 1px solid #ccc;
	box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.1);
	border-radius: 2.5px;
	background-color: var(--fucales-light);
	/* color: #0a7045; */
}

.ReactVirtualized__Grid__innerScrollContainer
	> .ReactVirtualized__Table__row
	> .ReactVirtualized__Table__rowColumn {
	padding: 0 !important;
}
