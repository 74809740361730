.radio-group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.radio-group h1 {
  font-size: 0.9rem;
  font-weight: lighter;
  color: #999;
}
.radio-group .radio {
  margin: 0.3rem 0.5rem;
  font-size: 0.8rem;
}
