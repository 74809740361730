.image {
	height: 310px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #aaa;
}

.image span {
	font-size: 1.5rem;
	font-weight: bold;
	color: #fff;
}

.image img {
	max-height: 310px;
	max-width: 100%;
}
