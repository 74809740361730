#resource-map {
	margin-left: 3rem;
	width: calc(100% - 5rem);
	height: calc(100% - 5rem);
	box-shadow: var(--box-shadow);
}

svg .point {
	fill-opacity: 0.7;
	fill: var(--primary-color);
	stroke: none;
	stroke-width: 2px;
}

svg .peer {
	fill: var(--primary-color);
	opacity: 1;
}

svg .highlight {
	stroke: var(--fucales);
	stroke-opacity: 0.8;
	stroke-width: 4px;
}

.stationLink {
	text-align: center;
	text-decoration: underline;
	font-size: 0.9rem;
	font-weight: bold;
	color: var(--success-dark);
	cursor: pointer;
}
