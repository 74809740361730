.drawer {
	position: absolute;
	top: 0px;
	right: 0;
	background: #fff;
	box-shadow: var(--box-shadow);
	width: 500px;
	height: calc(100vh - 50px);
	z-index: 500;
	overflow: auto;
	transform: translate(550px);
	transition: transform 300ms ease;
	padding-bottom: 2rem;
}

.drawer .title {
	width: 100%;
	position: relative;
	border-bottom: var(--border);
	padding: 0.7rem 1rem 0.5rem 2rem;
	font-size: 20px;
	font-weight: bold;
}

/* Close button */
.drawer > svg:nth-of-type(1) {
	position: absolute;
	top: 1rem;
	right: 1rem;
	fill: #000;
	fill-opacity: 0.4;
	cursor: pointer;
	transition: fill-opacity 0.3s ease;
	z-index: 1;
}
.drawer > svg:hover:nth-of-type(1) {
	fill-opacity: 0.8;
}

.drawer > .spinner {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
