/* Remove formatting */
.react-datepicker {
	font-family: inherit;
	border: 0px;
}

.react-datepicker .react-datepicker__current-month {
	font-weight: 400;
}

.react-datepicker .react-datepicker__header {
	background-color: white;
	border: 0px;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
	font-family: inherit;
	border-radius: var(--border-radius);
	appearance: none;
	border: 1px solid hsl(0, 0%, 80%);
	padding: 0.5rem;
	font-size: 0.85rem;
	text-overflow: ellipsis;
	width: 6rem;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: transparent;
	border: 1px solid var(--light-grey);
}

/* Styles */

.datepickers-popup-container {
	width: 275px;
	background-color: #fff;
	box-shadow: var(--box-shadow);
	padding: 1rem 1rem 1.5rem 1rem;
	margin-bottom: 4px;
}

.datepickers-popup-container .apply {
	display: flex;
	justify-content: stretch;
	margin: 0 0.75rem;
}

.datepickers-popup-container .apply button {
	width: 100%;
}

.datepickers {
	display: flex;
	justify-content: space-between;
}

.timepicker {
	display: flex;
	margin: 0.35rem 0.8rem 1.55rem 0.8rem;
	justify-content: space-between;
	font-size: 0.9rem;
	height: 4rem;
}
.timepicker label {
	display: block;
	padding: 0.2rem;
	margin-right: 0.2rem;
	margin-bottom: 0.25rem;
}

.react-datepicker-popper {
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: var(--border-radius);
	z-index: 100001;
	background-color: white;
}

.react-datepicker {
	position: relative;
	background: #fff;
	font-size: 0.8rem;
}

.react-datepicker__header {
	position: relative;
	text-align: center;
	padding-top: 8px;
}

button.react-datepicker__navigation {
	background: transparent;
	line-height: 1.7rem;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 10px;
	width: 0;
	padding: 0;
	border: 0.45rem solid transparent;
	z-index: 100000;
	height: 10px;
	width: 10px;
	text-indent: -999em;
	overflow: hidden;
}
button.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: #ccc;
}
button.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: #ccc;
}

.react-datepicker__current-month {
	font-size: 0.9rem;
}

.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
	cursor: pointer;
}
.react-datepicker__day:hover {
	background-color: var(--primary-color);
	color: #fff;
}
.react-datepicker__day-name {
	font-weight: bolder;
	color: red;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
	user-select: none;
}

.react-datepicker__day:hover {
	border-radius: 2px;
	background-color: var(--primary-color);
	color: #fff;
}
.react-datepicker__day--selected {
	border-radius: 2px;
	background-color: var(--primary-color);
	color: #fff;
}

.react-datepicker__day--outside-month {
	color: #aaa;
}

#filter-anchor div.date-time-select label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 30px;
	background-color: #fff;
	font-size: 0.8rem;
	border-radius: 2px;
	border-style: solid;
	border-color: hsl(0, 0%, 80%);
	border-width: 1px;
	margin-bottom: 4px;
}

#filter-anchor div.date-time-select label input {
	font-size: 0.7rem;
	font-weight: 400;
}

#filter-anchor div.date-time-select .input-select-results-container {
	position: static;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: var(--carrot-darker);
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
	color: #000;
	background-color: var(--carrot);
}

.react-datepicker__time-list-item--selected {
	background-color: var(--button-color) !important;
	color: var(--button-text-color) !important;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__time-list-item--selected:hover {
	background-color: var(--button-hover-color) !important;
}
